@import './css/new-game.css';
@import './css/game.css';

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}

svg {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    position: absolute;
}
