.new-game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: lightblue;
    min-height: 100vh;
}

.new-game-grid {
    display: grid;
    grid-template-columns: repeat(3, auto);
    row-gap: 20px;
    column-gap: 40px;
}

.new-game__puzzle-details-container {
    display: grid;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.new-game__puzzle-image {
    width: 250px;
    height: 200px;
    background-size: cover;
    cursor: pointer;
    flex-grow: 0;
}

.new-game__puzzle-name {
    padding-top: 10px;
    text-align: center;
    font-size: 1.5rem;
    cursor: pointer;
    flex-grow: 0;
}

.new-game__puzzle-size-container {
    display: flex;
    flex-direction: column;
    margin: 5px;
    flex-grow: 1;
}

.new-game__puzzle-size-btn {
    margin: 4px;
    padding: 4px;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
}

.new-game__puzzle-size-btn:hover {
    background-color: darkblue;
    color: white;
}
