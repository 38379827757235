.game-container {
    display: grid;
    position: relative;
    min-height: 100vh;
    background-color: aquamarine;
    overflow: hidden;
}

.piece-wrapper {
    pointer-events: none;
}

.piece {
    background-color: lightskyblue;
    pointer-events: auto;
    cursor: pointer;
}

.game-buttons-container {
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.game__button {
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    background-color: rgba(20, 60, 160, 0.3);
    border: none;
    font-size: 1.25rem;
}

.game__button:hover {
    background-color: rgba(20, 60, 160, 0.5);
}
